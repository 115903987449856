import { get, post, put, Delete } from "@/api/axios";

// 获取banner列表
export const list = params => get('/web/home/banner/list', params);

// 添加banner
export const add = params => post('/web/home/banner/add', params);

// 修改banner
export const update = params => put('/web/home/banner/update', params);

// 删除banner
export const deleteNew = params => Delete('/web/home/banner/delete/' + params.id, '');

// 查询banner详情
export const view = params => get('/web/home/banner/view/' + params.id, '');

//景区详情
export const regionList = () => get('/web/home/banner/region/list')

