<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div class="flex justify-content-space-between align-items marginBottom20">
          <div class="flex align-items">
            <div class="flex align-items marginRight10">
              <span class="span marginRight10">标题 : </span>
              <div class="width200">
                <el-input
                  v-model="searchData.title"
                  placeholder="请输入标题"
                  maxlength="20"
                  @keyup.enter.native="handleSearch"
                ></el-input>
              </div>
            </div>
            <el-button
              size="medium"
              type="primary"
              icon="el-icon-search"
              @click="handleSearch"
              class="marginLeft10"
            >查询</el-button>
            <el-button
              size="medium"
              type="info"
              icon="el-icon-refresh-right"
              @click="handleReset"
              class="marginLeft10"
            >重置</el-button>
          </div>

          <el-button
            size="medium"
            type="primary"
            icon="el-icon-plus"
            @click="handleCreate"
          >添加</el-button>
        </div>
      </template>
      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          type="text"
          size="mini"
          icon="el-icon-edit"
          @click="handleEdit(row)"
        >编辑</el-button>
        <el-button
          type="text"
          size="mini"
          icon="el-icon-delete"
          @click="handleDelete(row)"
        >删除</el-button>
      </template>
    </avue-crud>

  </div>
</template>

<script>
import { deleteNew, list } from "@/api/home/banner";
export default {
  name: "index",
  data() {
    return {
      searchData: {
        title: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "banner管理",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "标题",
            prop: "title",
          },
          {
            label: "排序值",
            prop: "sort",
          },
          {
            label: "状态",
            prop: "state",
            formatter: function (row, column, cellValue) {
              return row.state === 1 ? "显示" : row.state === 2 ? "隐藏" : "/";
            },
          },
          {
            label: "添加日期",
            prop: "createTime",
          },
        ],
      },

      dialogVisible: false,
      form: {
        id: "",
        type: "",
        name: "",
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify(searchForm));
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    // 添加
    handleCreate() {
      this.$router.push({
        path: "/home/banner/create",
        query: {
          type: "create",
        },
      });
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      localStorage.removeItem("searchForm");
      this.searchData = {
        title: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    handleEdit(row) {
      // // console.log(row)
      this.$router.push({
        path: "/home/banner/create",
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },
    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteNew({
          id: row.id,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        type: "",
        name: "",
      };
    },
  },
};
</script>

<style scoped>
</style>